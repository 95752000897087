@import '../themes/variables';
@import '../themes/color-palette';
@import '../themes/mixin/responsive';
@import '../themes/mixin/line-clamp';

.landing_main_cat_menu_container {
  display: flex;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  
  .landing_main_cat_item {
    position: relative;
    width: 33.333333%;
    padding-top: 41.667%;
    overflow: hidden;
    cursor: pointer;
    background-color: $gray-light-4;

    &:nth-child(even) {
      background-color: $gray-light-5;
    }

    .main_cat_title, .main_cat_image, .main_cat_see_more_btn {
      position: absolute;
    }
    .main_cat_title {
      top: 11.67%;
      width: 100%;
      color: $button-1;
      text-align: center;
      white-space: nowrap;
    }

    .main_cat_image {
      top: 57.5%;
      transform: translate(-50%, -50%);

      &.main_cat_image_1 {
        left: 50%;
        width: 125%;
      }
      &.main_cat_image_2 {
        left: 66%;
        width: 145%;
      }
      &.main_cat_image_3 {
        left: 50%;
        width: 140%;
      }
    }
    
    .main_cat_see_more_btn {
      display: block;
      width: 30px;
      bottom: $space-reg-3;
      right: $space-reg-3;
    }

    @include breakpoint(reg) {
      .main_cat_title {
        font-size: $fs-reg;
      }
    }

    @include breakpoint(sm) {
      width: 100%;
      padding-top: 42.057%;

      .main_cat_image {
        transform: translate(-50%, 0%);

        &.main_cat_image_1, &.main_cat_image_2, &.main_cat_image_3 {
          top: 22.5%;
          width: 85%;
        }
        
        &.main_cat_image_2 {
          left: 50%;
        }
      }
      
      .main_cat_see_more_btn {
        display: block;
        width: 20px;
        bottom: $space-sm-2;
        right: $space-sm-2;
      }
    }
    
    @include breakpoint(xs) {
      padding-top: 45%;

      .main_cat_image {
        &.main_cat_image_1, &.main_cat_image_2, &.main_cat_image_3 {
          top: 27.5%;
          width: 80%;
        }
      }
    }

    @include breakpoint(xxxs) {
      .main_cat_image {
        &.main_cat_image_1, &.main_cat_image_2, &.main_cat_image_3 {
          top: 35%;
        }
      }
    }
  }
}

.floor_content_container {
  .floor_selector_container {
    display: flex;
    max-width: 640px;
    margin: 0 auto;
    padding: 0 $space-reg-1;
    border-bottom: solid 2px $silver;

    .floor_item {
      position: relative;
      display: block;
      font-size: $fs-reg-2;
      color: $grey-dark-3;
      padding: $space-sm-1 $space-reg-3;

      &.floor_item_active {
        color: $button-1;

        &:after {
          display: block;
          content: '';
          position: absolute;
          width: calc(100% - 40px);
          height: 4px;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
          background-color: $button-1;
        }
      }
    }

    @include breakpoint(xs) {
      margin: 0 -16px;
      justify-content: space-between;

      .floor_item {
        font-size: $fs-reg-2;
        padding: $space-sm-1 $space-sm-2;

        &.floor_item_active {
          &:after {
            width: calc(100% - 10px);
          }
        }
      }
    }
  }

  .floor_map_content_container {
    display: flex;
    align-items: center;
    padding: 150px 0 200px;
    
    .floor_map_block, .floor_content_block {
      width: 50%;
    }
    .floor_map_block {
      padding-right: $space-md-1;

      img {
        display: none;

        &.floor_map_img_active {
          display: block;
        }
      }
    }
    .floor_content_block {
      padding-left: $space-md-1;

      .floor_content_item {
        display: none;

        &.floor_content_item_active {
          display: block;
        }
      }
    }

    .floor_main_title {
      font-size: $fs-xl;
      color: $button-1;

      @include breakpoint(xs) {
        font-size: $fs-lg;
      }
    } 
    .floor_sub_title {
      font-size: $fs-lg;
      color: $grey-dark-3;

      @include breakpoint(xs) {
        font-size: $fs-md;
      }
    } 
    .floor_desc {
      font-size: $fs-reg-2;
      color: $grey-dark-2;
      padding-top: $space-reg-2;
    }
    
    @include breakpoint(sm) {
      max-width: 480px;
      margin: 0 auto;
      flex-wrap: wrap;
      padding: $space-md-2 0 120px;
      
      .floor_map_block, .floor_content_block {
        width: 100%;
      }
      .floor_map_block {
        padding-right: 0;
      }
      .floor_content_block {
        padding-top: $space-md-1;
        padding-left: 0;
      }
    }
  }
}

.promotion_content_container {
  position: relative;
  display: flex;
  z-index: 10;

  @include breakpoint(reg) {
    flex-wrap: wrap;
  }
  
  .promotion_item {
    position: relative;
    width: 50%;

    @include breakpoint(reg) {
      width: 100%;
    }

    .promotion_content_block {
      position: absolute;
      height: 100%;
      width: 75%;
      max-width: 360px;
      max-height: 568px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include breakpoint(md) {
        width: 50%;
        height: auto;
      }
      @include breakpoint(reg) {
          min-height: 480px;
      }
      @include breakpoint(sm) {
          min-height: 520px;
      }
      @include breakpoint(xs) {
        width: 60%;
        min-height: 420px;
      }

      &.promotion_content_block_1 {
        color: $white;
        top: -10%;
        right: 0;
        background-color: $gold-3;


        @include breakpoint(reg) {
          right: unset;
          left: 0;
        }
      }

      &.promotion_content_block_2 {
        color: $button-1;
        bottom: -2.5%;
        left: 0;
        background-color: $gray-light-6;

        @include breakpoint(reg) {
          right: 0;
          left: unset;
        }
      }

      .promotion_title_block {
        padding: $space-md-2 $space-reg-1 $space-reg-1 $space-md-1;

        @include breakpoint(sm) {
          padding: $space-reg-2 $space-reg-3 $space-reg-1 $space-reg-3;
        }

        .promotion_title {
          padding-top: $space-sm-2;
          font-size: $fs-lg;
          line-height: $lh-lg;
          font-family: 'Chonburi', cursive;
          @include text-clamp(3);

          @include breakpoint(md) {
            font-size: $fs-md;
          }
          @include breakpoint(sm) {
            @include text-clamp(4);
          }
          @include breakpoint(xs) {
            font-size: $fs-reg;
          }
        }
      }

      .promotion_desc_block {
        padding: $space-reg-1 $space-reg-1 $space-md-2 $space-md-1;

        @include breakpoint(sm) {
          padding: $space-reg-1 $space-reg-3 $space-reg-2 $space-reg-3;
        }

        .promotion_desc {
          @include text-clamp(4);

          @include breakpoint(sm) {
            @include text-clamp(6);
          }
        }

        .promotion_link {
          display: inline-block;
          margin-top: $space-reg-1;
        }
      }
    }
  }
}

.about_us_container {
  position: relative;
  padding: 125px 0;
  
  @include breakpoint(sm) {
    padding: $space-lg-2 0;
  }
  @include breakpoint(xs) {
    padding: $space-lg-1 0;
  }

  .about_img_bg {
    position: absolute;
    width: 50%;
    right: -15%;
    bottom: -5%;
    transform: scaleX(-1);

    @include breakpoint(reg) {
      width: 65%;
    }
    @include breakpoint(sm) {
      right: unset;
      left: -15%;
      transform: scaleX(1);
    }
    @include breakpoint(xs) {
      width: 75%;
    }
  }

  .about_us_block {
    display: flex;
    color: $button-1;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(sm) {
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
      flex-direction: column;
    }

    .about_item_1 {
      width: 33%;
      max-width: 310px;

      @include breakpoint(reg) {
        width: 45%;
      }
      @include breakpoint(sm) {
        width: 100%;
      }
    }
    .about_item_2 {
      width: 40%;
      padding: 0 $space-reg-2;
      
      @include breakpoint(sm) {
        max-width: 480px;
        width: 100%;
        padding: $space-reg-3 0 $space-md-2;
      }
    }
    .about_item_3 {
      width: 27%;
      max-width: 225px;

      @include breakpoint(reg) {
        width: 15%;
      }
      @include breakpoint(sm) {
        width: 100%;
      }
    }

    .about_title {
      font-size: $fs-xl;

      @include breakpoint(sm) {
        font-size: $fs-lg;
      }
    }
  }
}

.news_activity_content_container {
  position: relative;
  display: flex;
  align-items: flex-start;
  overflow-y: auto;
  
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .maya_info_block {
    position: relative;
    width: 50%;
    padding-top: 55%;
    overflow: hidden;

    @include breakpoint(md) {
      padding-top: 0;
    }
    @include breakpoint(sm) {
      width: 100%;
    }
    
    .maya_info_img_block {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include breakpoint(md) {
        position: relative;
        width: 100%;
        left: unset;
        top: unset;
        transform: translate(0, 0);
      }
    }

    .maya_info_container {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 320px;
      min-height: 560px;
      width: 80%;
      top: 0;
      left: 10%;
      color: $white;
      padding: $space-md-2 $space-reg-3;
      background-color: $gold-3;

      @include breakpoint(reg) {
        min-height: 480px;
      }
      @include breakpoint(sm) {
        position: relative;
        left: unset;
        max-width: 260px;
        margin-top: -120px;
        margin-bottom: $space-lg-2;
        margin-left: auto;
        margin-right: auto;
        padding: $space-md-2 $space-md-1;
      }

      .maya_info_title_block {
        .maya_info_sub_title {
          font-size: $fs-reg;
          font-weight: $fs-w-base;
          padding-top: $space-reg-3;
        }
        .maya_info_title {
          font-size: 44px;
          font-weight: $fs-w-base;
        }
      }
    }
  }

  .maya_new_activity_container {
    width: 50%;
    min-height: 1200px;
    padding: 0 $space-reg-2;

    @include breakpoint(sm) {
      width: 100%;
      min-height: 0;
    }

    .new_activity_block {
      max-width: 480px;
      margin: 0 auto;

      @include breakpoint(sm) {
        max-width: 100%;
      }
    }
  }
}