@import '../themes/variables';
@import '../themes/color-palette';
@import '../themes/mixin/responsive';
@import '../themes/mixin/line-clamp';

.highlight_item {
  display: flex;
  align-items: center;
  margin-top: -$space-reg-2;

  @include breakpoint(sm) {
    margin-top: 0;
    flex-wrap: wrap;
  }

  .info_block {
    width: 50%;
    line-height: $lh-xl;
    padding-right: $space-reg-2;

    .content_type {
      display: flex;
      span {
        display: block;
      }
      .text {
        padding: 0 $space-xs-1;
      }
      .star {
        width: 16px;
      }
    }
    .content_title {
      width: 75%;
      margin: $space-reg-2 0;
      @include text-clamp(3);
    }
    .content_desc {
      max-width: 400px;
      padding-bottom: $space-reg-2;
      @include text-clamp(4);
    }

    @include breakpoint(sm) {
      width: 100%;
      padding: 25px $space-md-1 $space-reg-2 $space-md-1;
      
      .content_title {
        width: 100%;
      }
    }

    @include breakpoint(xxxs) {
      padding-left: $space-reg-1;
      padding-right: $space-reg-1;
    }
  }
  .img_block {
    width: 50%;

    @include breakpoint(sm) {
      position: relative;
      width: 100%;
      padding-top: 90%;
      overflow: hidden;
      
      img {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}