@import '../themes/variables';
@import '../themes/color-palette';
@import '../themes/mixin/responsive';

.maya_bg_container {
  position: relative;
  overflow: hidden;
  z-index: 10;

  &.with_minus_top {
    margin-top: -$space-reg-3;

    @include breakpoint(sm) {
      margin-top: 0;
    }
  }

  .bg_top, .bg_bottom {
    position: absolute;
    display: block;
    content: '';
  }
  .bg_top {
    top: -40px;
    right: 0;
    width: 60%;

    @include breakpoint(sm) {
      top: -7.5%;
      right: -40%;
      width: 80%;
    }
  }
  .bg_bottom {
    bottom: -80px;
    left: 0;
    width: 50%;
  }

  &.maya_bg_type_2 {
    .bg_top {
      top: -12.5%;
      right: -25%;
    }

    @include breakpoint(sm) {
      .bg_bottom {
        bottom: -2.5%;
        left: 0;
        width: 70%;
      }
    }
  }
}