@mixin breakpoint($class) {

  @if $class == xxxs {
      @media (max-width: 360px) { @content; }
  }

  @else if $class == xxs {
    @media (max-width: 480px) { @content; }
  }

  @else if $class == xs {
      @media (max-width: 577px) { @content; }
  }

  @else if $class == sm {
      @media (max-width: 767px) { @content; }
  }

  @else if $class == reg {
      @media (max-width: 990px) { @content; }
  }

  @else if $class == md {
      @media (max-width: 1120px) { @content; }
  }

  @else if $class == lg {
      @media (max-width: 1400px) { @content; }
  }

  @else {
      @warn "Breakpoint mixin supports: xxxs, xs, sm, md, lg";
  }
}
