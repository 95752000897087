$gold-2: #e1d4aa;
$gold-3: #d5c797;
$champagne: #fbf4d6;
$button-1: #c1ac69;
$button-1-hover: #b8972d;
$grey-dark-2: #848274;
$grey-dark-3: #bbb8ab;
$grey-light-1: #d4d2ca;
$grey-light-2: #e5e3de;
$grey-light-3: #f1efe9;
$gray-light-4: #F5F3EF;
$gray-light-5: #F0EFE5;
$gray-light-6: #f8f7f2;
$gray-light-7: #c4c3b6;
$gray-light-8: #d8d8d8;
$silver: #eeede0;
$grey-1: #42403b;
$black: #2f2d2a;
$black-000: #000;
$white: #fff;
$colors-3-status-green: #bdc92e;
$colors-3-status-blue: #00b3d2;
$colors-3-status-orange: #e6782f;
$dark-bg-no-border: #76756e;
$error: #c81b5a;