// Space
$space-xs-1             : 4px;
$space-xs-2             : 5px;
$space-sm-1             : 8px;
$space-sm-2             : 10px;
$space-sm-3             : 12px;
$space-reg-1            : 16px;
$space-reg-2            : 20px;
$space-reg-3            : 25px;
$space-md-1             : 32px;
$space-md-2             : 40px;
$space-lg-1             : 64px;
$space-lg-2             : 80px;

$fs-xl                  : 58px;
$fs-lg                  : 50px;
$fs-md                  : 40px;
$fs-reg                 : 30px;
$fs-reg-2               : 24px;
$fs-sm                  : 20px;
$fs-xs                  : 16px;
$fs-xxs                 : 14px;

$fs-w-base              : 300;
$fs-w-bold              : normal;

$lh-base                : 1;
$lh-lg                  : 1.25;
$lh-xl                  : 1.5;