@import '../themes/variables';
@import '../themes/color-palette';
@import '../themes/mixin/responsive';
@import '../themes/mixin/line-clamp';

.footer {
  position: relative;
  background-color: $button-1;
  color: $white;
  padding: $space-md-1 0;
  z-index: 9990;

  .footer_content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(sm) {
      flex-direction: column;
    }

    .footer_info_container {
      padding-right: $space-reg-2;

      @include breakpoint(sm) {
        order: 2;
        text-align: center;
      }
    }
    .footer_social_container {
      display: flex;
      align-items: center;
      padding-left: $space-reg-2;

      .footer_social_icon {
        width: 24px;
        margin-left: $space-md-1;

        &:first-child {
          margin-left: 0;
        }
      }

      @include breakpoint(sm) {
        order: 1;
        padding-bottom: $space-reg-2;
      }
    }
  }
}