@import '../themes/variables';
@import '../themes/color-palette';
@import '../themes/mixin/responsive';

header {
  &.header {
    position: fixed;
    width: 100%;
    z-index: 9999;
  }

  .header_content {
    position: relative;
    background-color: $white;
    box-shadow: 0 6px 10px 0 rgba(69, 73, 91, 0.08);

    @include breakpoint(reg) {
      box-shadow: 0 6px 10px 0 rgba(69, 73, 91, 0.22);
    }

    .header_content_container {
      display: flex;
      flex-direction: row;

      @include breakpoint(reg) {
        flex-wrap: wrap;
      }
    }

    .logo_container {
      position: relative;
      padding: 21px 0 18px 0;

      .logo_block {
        width: 100px;
      }

      .hamburger_menu_button {
        display: none;
      }
      .shopping_mb_button {
        display: none;
      }

      @include breakpoint(reg) {
        display: flex;
        height: 80px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        
        .hamburger_menu_button {
          display: flex;
          padding: $space-sm-2;
          margin-right: $space-reg-1;

          .hamburger_menu_btn {
            display: block;
            width: 20px;
          }
          .close_menu_btn {
            display: none;
            width: 20px;
          }

          &.mb_menu_active {
            .hamburger_menu_btn {
              display: none;
            }
            .close_menu_btn {
              display: block;
            }
          }
        }
        .shopping_mb_button {
          display: flex;
          align-self: stretch;
          max-height: 32px;
          background-color: $button-1;
          transition: background-color ease .25s;
          padding: 0 $space-reg-2;
          margin-top: $space-xs-2;

          .shop_now_icon {
            width: 16px;
          }
        }
      }
    }

    .main_header_content {
      position: relative;
      background-color: $white;
      z-index: 10;
    }

    .menu_container {
      flex: 0 1 auto;

      @include breakpoint(reg) {
        position: absolute;
        display: none;
        width: 100%;
        margin-left: -16px;
        margin-right: -16px;
        top: 146px;
        background-color: $grey-light-3;

        &.mb_menu_container_active {
          display: block;
        }
      }

      ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;

        @include breakpoint(reg) {
          flex-direction: column;
        }
        
        li {
          text-align: center;
          padding: 0 $space-reg-2;

          @include breakpoint(md) {
            padding: 0 $space-sm-2;
          }
          @include breakpoint(reg) {
            display: block;
            padding: 0 $space-reg-1;

            &:last-child {
              .menu_item {
                border-bottom: none;
              }
            }
          }
        }
      }

      .menu_item {
        position: relative;
        color: $grey-dark-2;
        text-decoration: none;
        padding: $space-md-1 0;
        white-space: nowrap;
        transition: color ease .25s;
        
        @include breakpoint(reg) {
          display: block;
          font-size: $fs-sm;
          text-align: left;
          padding: $space-reg-1 $space-reg-1;
          border-bottom: solid 1px $grey-light-1;
        }

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 100%;
          height: 4px;
          left: 0;
          bottom: 4px;
          opacity: 0;
          background-color: $button-1;
          transition: opacity ease .25s;

          @include breakpoint(reg) {
            display: none;
          }
        }

        &:hover {
          color: $button-1;
          
          &:before {
            opacity: 1;
          }
        }
      }
    }

    .search_lang_container {
      font-size: $fs-xxs;
      color: $dark-bg-no-border;

      @include breakpoint(reg) {
        display: none !important;
      }

      .search_button {
        display: flex;
        max-width: 32px;
        padding: $space-sm-1;
        margin-right: $space-sm-1;
      }

      .shopping_button_block {
        padding-left: $space-reg-1;
        border-left: solid 1px $silver;
      }
      .shop_now_button {
        background-color: $button-1;
        font-size: $fs-xs;
        color: $white;
        white-space: nowrap;
        text-decoration: none;
        padding: 0 $space-reg-2;
        transition: background-color ease .25s;

        .shop_now_icon {
          max-width: 16px;
          margin-right: $space-sm-2;
        }

        &:hover {
          background-color: $button-1-hover;
        }
      }
    }

    .language_selector_block {
      position: relative;
      margin-right: $space-sm-1;

      @include breakpoint(reg) {
        width: 58px;
        margin-left: $space-sm-1;
        margin-right: 0;
      }
    }
    .language_selector {
      padding: 11px $space-sm-1;

      .language_value {
        width: 20px;
        text-align: center;
      }
    }
    .language_option_block {
      display: none;
      position: absolute;
      width: 100%;
      text-align: center;
      background-color: $white;
      box-shadow: 0 6px 10px 0 rgba(69, 73, 91, 0.08);

      &.language_option_block_active {
        display: block;
      }

      a {
        display: block;
        padding: $space-xs-2;
        
        &:hover {
          color: $white;
          background-color: $gold-2;
        }
      }

      .language_option_selected {
        color: $white;
        background-color: $button-1;
      }
    }

    .website_search_container {
      position: absolute;
      width: 100%;
      top: -84px;
      padding: $space-reg-2 0;
      background-color: $silver;
      transition: top ease .75s;
      
      @include breakpoint(reg) {
        display: none;
        background-color: $white;
        top: 80px;
        z-index: 10;
        padding-top: 0;
        transition: none;
        box-shadow: 0 6px 10px 0 rgba(69, 73, 91, 0.08);
        
        &.mb_website_search_container_active {
          display: block;
        }
      }

      &.website_search_container_active {
        top: 100%;

        @include breakpoint(reg) {
          top: 80px;
        }
      }

      .search_form_control_block {
        @include breakpoint(reg) {
          align-items: center;
        }

        .search_form_control {
          background-color: $white;
          border: none !important;
          line-height: $lh-xl;
          padding: $space-sm-2 $space-reg-2;
          
          @include breakpoint(reg) {
            border: solid 1px $silver !important;
          }
        }
        .website_search_button {
          .website_search_icon {
            width: 30px;
            padding: 0 6px;
          }

          @include breakpoint(reg) {
            align-self: stretch;
          }
        }
      }

      .language_selector_block {
        display: none !important;

        @include breakpoint(reg) {
          display: flex !important;

          .language_icon {
            width: $space-reg-1;
          }
        }
      }
    }
  }
}